// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import { useEffect, useState } from "react";

import AdicionarDado from "./components/addData";
import DetalharDado from "./components/detailData";
import EditarDado from "./components/editData";
import tableData from "./components/tableData";

import WarehouseService from "services/warehouses";

import { TextField } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation } from "react-router-dom";
import { ptBR_DataGrid } from "utils/datagrid";
import Filter from "../payables/filter";

export default function EstoqueTransitorio() {
  const [showAddState, setShowAddState] = useState(false);
  const [showEditState, setShowEditState] = useState(false);
  const [showFilterState, setShowFilterState] = useState(false);
  const [showDetailState, setShowDetailState] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [currentIdAction, setCurrentIdAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warehouseId, setWarehouseId] = useState({
    id: "",
    nome: "",
  });
  const [warehouses, setWarehouses] = useState([]);

  const [staticPagination, setStaticPagination] = useState({
    page: 0,
    pageSize: 50,
  });

  const [isFiltered, setIsFiltered] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});

  const location = useLocation();

  const handleDetail = (id) => {
    setCurrentIdAction(id);
    setShowDetailState(true);
  };

  const handleEdit = (id) => {
    setCurrentIdAction(id);
    setShowEditState(true);
    window.scrollTo(0, 0);
  };

  const handleDelete = (obj) => {
    setDialogRemove(true);
    setDialogText(obj.descricao);
    setCurrentIdAction(obj._id);
  };

  const cancelDelete = () => {
    setDialogRemove(false);
    setDialogText("");
    setCurrentIdAction(null);
  };

  const confirmDelete = async () => {
    await WarehouseService.delete(currentIdAction);
    setDialogRemove(false);
    fetchData();
  };

  const resetRows = () => {
    updateRows([]);
    setLoading();
    setIsFiltered(false);
    setCurrentFilter({});
    fetchData();
  };

  useEffect(() => {
    const data = async () => {
      const warehouses = await WarehouseService.list();
      setWarehouses(warehouses);
      warehouses &&
        setWarehouseId({
          id: warehouses?.data[0]?._id,
          nome: warehouses?.data[0]?.descricao,
        });
    };

    data();
  }, []);

  const { columns, rows, fetchData, updateRows, updatePagination, sortBy, filterBy, pageObject, sortStr, filterStr } = tableData(handleEdit, handleDelete, handleDetail, setLoading, warehouseId);

  useEffect(() => {
    if (location.state && location.state.id) {
      setCurrentIdAction(location.state.id);
      setShowEditState(true);
    }

    if (location.state && location.state.contasReceberVencendoHoje) {
      setCurrentFilter({
        vencendoHoje: "S",
      });

      const doFilterLocation = async () => {
        const newRows = await WarehouseService.filter({
          vencendoHoje: "S",
        });

        updateRows(newRows);
        setIsFiltered(true);
      };

      setTimeout(() => {
        doFilterLocation();
      }, 3000);
    }
  }, [location, loading]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {showEditState && <EditarDado setShowEditState={setShowEditState} currentIdAction={currentIdAction} fetchData={fetchData} warehouseId={warehouseId} />}
        {showAddState && <AdicionarDado setShowAddState={setShowAddState} fetchData={fetchData} warehouseId={warehouseId} />}
        {showFilterState && (
          <Filter
            Service={WarehouseService}
            resetRows={resetRows}
            updateRows={updateRows}
            setIsFiltered={setIsFiltered}
            setShowFilterState={setShowFilterState}
            setCurrentFilter={setCurrentFilter}
            currentFilter={currentFilter}
            isContaReceber={true}
          />
        )}
        {showDetailState && <DetalharDado setShowDetailState={setShowDetailState} currentIdAction={currentIdAction} fetchData={fetchData} />}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" color="white" mt={1}>
                  Estoque Transitorio
                </MDTypography>
                <div>
                  <MDButton variant="contained" type="button" onClick={() => setShowAddState(true)} disabled={warehouseId.id === ""}>
                    Adicionar
                  </MDButton>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <TextField
                  select
                  label="Selecione o Depósito"
                  onChange={(e) =>
                    setWarehouseId({
                      id: e.target.value,
                      nome: e.target.options[e.target.selectedIndex].text,
                    })
                  }
                  value={warehouseId.id}
                  SelectProps={{
                    native: true,
                  }}
                  sx={{
                    width: "400px",
                    margin: "0 0 20px 20px",
                  }}
                >
                  {warehouses?.data?.length > 0 &&
                    warehouses?.data?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                </TextField>
                <Card sx={{ padding: 2 }}>
                  {isFiltered && "Filtrando Registros"}

                  {isFiltered ? (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSizeOptions={[50, 100]}
                      rowSelection={false}
                      loading={loading}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                      onPaginationModelChange={(model) => setStaticPagination(model)}
                    />
                  ) : (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      rowCount={pageObject.totalRows}
                      pageSizeOptions={[50, 100]}
                      paginationModel={{
                        pageSize: pageObject.perPage,
                        page: pageObject.page,
                      }}
                      paginationMode="server"
                      onPaginationModelChange={updatePagination}
                      sortingMode="server"
                      onSortModelChange={sortBy}
                      filterMode="server"
                      onFilterModelChange={filterBy}
                      rowSelection={false}
                      loading={loading}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                    />
                  )}
                </Card>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <MDDialog
          open={dialogRemove}
          handleClose={() => setDialogRemove(false)}
          title={"Tem certeza que deseja remover?"}
          actions={
            <>
              <MDButton variant="gradient" color="info" fullWidth type="submit" onClick={() => confirmDelete()}>
                Confirmar
              </MDButton>
              <MDButton variant="gradient" color="error" fullWidth type="submit" onClick={() => cancelDelete()}>
                Cancelar
              </MDButton>
            </>
          }
        >
          {dialogText}
        </MDDialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

import Dashboard from "layouts/dashboard";

import UserProfile from "layouts/user-profile";

import ForgotPassword from "auth/forgot-password";
import Login from "auth/login";
import ResetPassword from "auth/reset-password";

// @mui icons
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CategoryIcon from "@mui/icons-material/Category";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import HailIcon from "@mui/icons-material/Hail";
import HandshakeIcon from "@mui/icons-material/Handshake";
import InventoryIcon from "@mui/icons-material/Inventory";
import KeyIcon from "@mui/icons-material/Key";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import StoreIcon from "@mui/icons-material/Store";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import Icon from "@mui/material/Icon";

// Layout Types
import { InventoryRounded, ViewKanban } from "@mui/icons-material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AppsIcon from "@mui/icons-material/Apps";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DvrIcon from "@mui/icons-material/Dvr";
import Groups3Icon from "@mui/icons-material/Groups3";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleIcon from "@mui/icons-material/People";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Agenciadores from "layouts/agenciadores";
import AlmoxarifadoEstoque from "layouts/almoxarifado/estoque";
import AlmoxarifadoTransacoes from "layouts/almoxarifado/transacoes";
import Clientes from "layouts/clientes";
import Contas from "layouts/contas";
import DashboardVendas from "layouts/dashboard/vendas";
import Fornecedores from "layouts/fornecedores";
import Funcionarios from "layouts/funcionarios";
import GruposContas from "layouts/grupos-contas";
import GruposProduto from "layouts/grupos-produto";
import ImportarNotaFiscal from "layouts/importar/importar-nota-fiscal";
import { KanbanVenda } from "layouts/kanban-venda";
import LastNfeNumbers from "layouts/last-nfe-numbers";
import ContasAPagar from "layouts/payables";
import MetodosPagamento from "layouts/payment-methods";
import PedidosCompra from "layouts/pedidos-compra";
import PedidosVenda from "layouts/pedidos-venda";
import Permissoes from "layouts/permissoes";
import { AREAS } from "layouts/permissoes/components/yupSchema";
import Produtos from "layouts/produtos";
import ContasAReceber from "layouts/receivables";
import AcertoCliente from "layouts/relatorios/acerto-cliente";
import AcertoFornecedor from "layouts/relatorios/acerto-fornecedor";
import AlmoxarifadoReport from "layouts/relatorios/almoxarifado";
import CarregamentoDia from "layouts/relatorios/carregamento-dia";
import GenesisReport from "layouts/relatorios/genesis-report";
import SaldoDiario from "layouts/relatorios/saldo-diario";
import SaldoEntreDatas from "layouts/relatorios/saldo-entre-datas";
import VendaVendedor from "layouts/relatorios/venda-vendedor";
import SubgruposContas from "layouts/subgrupos-contas";
import Depositos from "layouts/warehouses";
import EstoqueTransitorio from "layouts/estoque-transitorio";
import PedidosCompraAlmoxarifado from "layouts/pedidos-compra-almoxarifado";
import Transportadoras from "./layouts/transportadora";
import LotesProdutos from "layouts/lotes-produtos";

const routes = [
  {
    type: "collapse",
    name: "Cadastros",
    key: "cadastros",
    icon: <ListAltIcon />,
    route: "#",
    children: [
      // Submenus com itens de cadastros
      {
        type: "collapse",
        name: "RH",
        key: "rh",
        icon: <PeopleIcon />,
        route: "#",
        children: [
          {
            type: "collapse",
            name: "Acessos do Usuário",
            key: AREAS.PERMISSION,
            icon: <KeyIcon />,
            route: "/permissoes",
            component: <Permissoes />,
          },
          {
            type: "collapse",
            name: "Funcionários",
            key: AREAS.USER,
            icon: <Icon fontSize="small">dashboard</Icon>,
            route: "/funcionarios",
            component: <Funcionarios />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Comercial",
        key: "comercial",
        icon: <HandshakeIcon />,
        route: "#",
        children: [
          {
            type: "collapse",
            name: "Clientes",
            key: AREAS.CUSTOMER,
            icon: <EmojiPeopleIcon />,
            route: "/clientes",
            component: <Clientes />,
          },
          {
            type: "collapse",
            name: "Fornecedores",
            key: AREAS.SUPPLIER,
            icon: <StoreIcon />,
            route: "/fornecedores",
            component: <Fornecedores />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Logística",
        key: "logistica",
        icon: <DvrIcon />,
        route: "#",
        children: [
          {
            type: "collapse",
            name: "Transportadoras",
            key: AREAS.SHIPPING_COMPANY,
            icon: <LocalShippingIcon />,
            route: "/transportadoras",
            component: <Transportadoras />,
          },
          {
            type: "collapse",
            name: "Agenciadores",
            key: AREAS.AGENT,
            icon: <HailIcon />,
            route: "/agenciadores",
            component: <Agenciadores />,
          },
          {
            type: "collapse",
            name: "Depósitos",
            key: AREAS.WAREHOUSE,
            icon: <WarehouseIcon />,
            route: "/warehouses",
            component: <Depositos />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Produtos",
        key: "produtos",
        icon: <AppsIcon />,
        route: "#",
        children: [
          process.env.REACT_APP_IS_PRIME_PLUS
            ? {
                type: "collapse",
                name: "Lotes de Produtos",
                key: AREAS.PRODUCT,
                icon: <InventoryRounded />,
                route: "/lotes-produtos",
                component: <LotesProdutos />,
              }
            : {},
          {
            type: "collapse",
            name: "Lista de Produtos",
            key: AREAS.PRODUCT,
            icon: <InventoryIcon />,
            route: "/produtos",
            component: <Produtos />,
          },
          {
            type: "collapse",
            name: "Grupos de Produto",
            key: AREAS.PRODUCT_GROUP,
            icon: <CategoryIcon />,
            route: "/grupos-produto",
            component: <GruposProduto />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Financeiro",
        key: "financeiro",
        icon: <AttachMoneyIcon />,
        route: "#",
        children: [
          {
            type: "collapse",
            name: "Últimos Números de NF-e",
            key: AREAS.LASTNFENUMBER,
            icon: <RequestQuoteIcon />,
            route: "/lastnfenumbers",
            component: <LastNfeNumbers />,
          },
          {
            type: "collapse",
            name: "Contas Bancárias",
            key: AREAS.ACCOUNT,
            icon: <RequestQuoteIcon />,
            route: "/contas",
            component: <Contas />,
          },
          {
            type: "collapse",
            name: "Centro de Custo",
            key: AREAS.ACCOUNT_GROUP,
            icon: <PeopleIcon />,
            route: "/grupos-contas",
            component: <GruposContas />,
          },
          {
            type: "collapse",
            name: "Natureza de Lançamento",
            key: AREAS.SUB_GROUP_ACCOUNT,
            icon: <Groups3Icon />,
            route: "/subgrupos-contas",
            component: <SubgruposContas />,
          },
          {
            type: "collapse",
            name: "Formas de Pagamento",
            icon: <PaymentsIcon />,
            key: AREAS.PAYMENT_METHOD,
            route: "/metodos-pagamento",
            component: <MetodosPagamento />,
          },
        ],
      },
      // submenus com itens de cadastros
    ],
  },
  {
    type: "collapse",
    name: "Financeiro",
    key: "financeiro",
    icon: <AttachMoneyIcon />,
    route: "#",
    children: [
      {
        type: "collapse",
        name: "Contas a pagar",
        icon: <LocalAtmIcon />,
        key: AREAS.PAYABLE,
        route: "/contas-pagar",
        component: <ContasAPagar />,
      },
      {
        type: "collapse",
        name: "Contas a receber",
        icon: <PaymentsIcon />,
        key: AREAS.RECEIVABLE,
        route: "/contas-receber",
        component: <ContasAReceber />,
      },
      {
        type: "collapse",
        name: "Importar Nota Fiscal",
        icon: <ReceiptIcon />,
        key: AREAS.PAYABLE,
        route: "/importar-nota-fiscal",
        component: <ImportarNotaFiscal />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Comercial",
    key: "pedidos",
    icon: <HandshakeIcon />,
    route: "#",
    children: [
      {
        type: "collapse",
        name: "Pedidos de Compra",
        key: AREAS.PURCHASE,
        icon: <ReceiptIcon />,
        route: "/pedidos-compra",
        component: <PedidosCompra />,
      },
      {
        type: "collapse",
        name: "Pedidos de Venda",
        key: AREAS.SALE,
        icon: <ReceiptLongIcon />,
        route: "/pedidos-venda",
        component: <PedidosVenda />,
      },
      {
        type: "collapse",
        name: "Kanban de Venda",
        key: AREAS.SALE,
        icon: <ViewKanban />,
        route: "/kanban-venda",
        component: <KanbanVenda />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Almoxarifado",
    key: "almoxarifado",
    icon: <InventoryIcon />,
    route: "#",
    children: [
      {
        type: "collapse",
        name: "Estoque",
        key: AREAS.STOCK,
        icon: <CategoryIcon />,
        route: "/estoque",
        component: <AlmoxarifadoEstoque />,
      },
      {
        type: "collapse",
        name: "Entradas e Saídas",
        key: AREAS.STOCK,
        icon: <SwapVertIcon />,
        route: "/estoque-entrada-saida",
        component: <AlmoxarifadoTransacoes />,
      },
      {
        type: "collapse",
        name: "Pedidos de Compra",
        key: AREAS.PURCHASE_WAREHOUSE,
        icon: <ReceiptIcon />,
        route: "/pedidos-compra-almoxarifado",
        component: <PedidosCompraAlmoxarifado />,
      },
      {
        type: "collapse",
        name: "Estoque Transitório",
        key: AREAS.STOCK,
        icon: <WarehouseIcon />,
        route: "/estoque-transitorio",
        component: <EstoqueTransitorio />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Relatórios",
    key: "relatorios",
    icon: <AssessmentIcon />,
    route: "#",
    children: [
      {
        type: "collapse",
        name: "Almoxarifado",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "/almoxarifado",
        component: <AlmoxarifadoReport />,
      },
      {
        type: "collapse",
        name: "Acerto com Fornecedor",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "/acerto-fornecedor",
        component: <AcertoFornecedor />,
      },
      {
        type: "collapse",
        name: "Acerto com Cliente",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "/acerto-cliente",
        component: <AcertoCliente />,
      },
      {
        type: "collapse",
        name: "Saldo Diário",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "/saldo-diario",
        component: <SaldoDiario />,
      },
      {
        type: "collapse",
        name: "Saldo Entre Datas",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "/saldo-entre-datas",
        component: <SaldoEntreDatas />,
      },
      {
        type: "collapse",
        name: "Carregamento do Dia",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "/carregamento-dia",
        component: <CarregamentoDia />,
      },
      // relatorio de produtividade
      {
        type: "collapse",
        name: "Relatório de Produtividade",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "/relatorio-produtividade",
        component: <GenesisReport />,
      },
      {
        type: "collapse",
        name: "Relatórios de Venda",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "#",
        children: [
          {
            type: "collapse",
            name: "Relatório por Período",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
          {
            type: "collapse",
            name: "Relatório por Cliente",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
          {
            type: "collapse",
            name: "Relatório por Vendedor",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/venda-vendedor",
            component: <VendaVendedor />,
          },
          {
            type: "collapse",
            name: "Relatório de Comissões",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Relatórios de Compra",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "#",
        children: [
          {
            type: "collapse",
            name: "Relatório por Período",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
          {
            type: "collapse",
            name: "Relatório por Fornecedor",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
          {
            type: "collapse",
            name: "Relatório por Comprador",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
          {
            type: "collapse",
            name: "Relatório de Comissões",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Relatórios de Contas a Receber",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "#",
        children: [
          {
            type: "collapse",
            name: "Relatório por Período",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
          {
            type: "collapse",
            name: "Relatório por Cliente",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },

          {
            type: "collapse",
            name: "Relatório de Contas Vencidas",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Relatórios de Contas a Pagar",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "#",
        children: [
          {
            type: "collapse",
            name: "Relatório por Período",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
          {
            type: "collapse",
            name: "Relatório por Fornecedor",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },

          {
            type: "collapse",
            name: "Relatório de Contas Vencidas",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Relatórios Estratégicos",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "#",
        children: [
          {
            type: "collapse",
            name: "Relatório de Lucratividade Estimada",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },

          {
            type: "collapse",
            name: "Relatório de Lucratividade Estimada por Fornecedor",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },

          {
            type: "collapse",
            name: "Relatório de Receita x Despesa",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },

          {
            type: "collapse",
            name: "Gráfico de Vendas",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },

          {
            type: "collapse",
            name: "Gráfico de Compras",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },

          {
            type: "collapse",
            name: "CCV (Custo Carga Vendida)",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
        ],
      },
      {
        type: "collapse",
        name: "DRE",
        key: AREAS.REPORT,
        icon: <AddBusinessIcon />,
        route: "#",
        children: [
          {
            type: "collapse",
            name: "Relatório de Despesas",
            key: AREAS.REPORT,
            icon: <AddBusinessIcon />,
            route: "/acerto-fornecedor",
            component: <AcertoFornecedor />,
          },
        ],
      },
    ],
  },
  {
    type: "examples",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "examples",
    name: "Dashboard de Vendas",
    key: AREAS.REPORT,
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard-vendas",
    component: <DashboardVendas />,
  },
  {
    type: "examples",
    name: "Meus Dados",
    key: "user-profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user-profile",
    component: <UserProfile />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },

  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "examples",
  //   name: "User Management",
  //   key: "user-management",
  //   icon: <Icon fontSize="small">list</Icon>,
  //   route: "/user-management",
  //   component: <UserManagement />,
  // },
  {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  {
    type: "auth",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/forgot-password",
    component: <ForgotPassword />,
  },
  {
    type: "auth",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/reset-password",
    component: <ResetPassword />,
  },
];

export default routes;
